import {
    BackstageIdentityResponse,
    configApiRef,
    SignInPageProps,
    useApi,
  } from '@backstage/core-plugin-api';
  import Button from '@material-ui/core/Button';
  import Grid from '@material-ui/core/Grid';
  import Typography from '@material-ui/core/Typography';
  import React, { useState } from 'react';
  import { useMountEffect } from '@react-hookz/web';
  import {
    Page,
    Content,
    InfoCard,
    Progress,
    Header,
    UserIdentity,
  } from '@backstage/core-components';
  import { makeStyles } from '@material-ui/core/styles';
  import { coreComponentsTranslationRef } from '@backstage/core-components/alpha';
  import { useTranslationRef } from '@backstage/core-plugin-api/alpha';
  import { SignInProviderConfig } from '@backstage/core-components';
  
  export const useStyles = makeStyles(
    {
      container: {
        padding: 0,
        listStyle: 'none',
      },
      item: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '400px',
        margin: 0,
        padding: 0,
      },
    },
    { name: 'BackstageSignInPage' },
  );

  type SingleSignInPageProps = SignInPageProps & {
    provider: SignInProviderConfig;
    auto?: boolean;
  };
  
  export type Props = SingleSignInPageProps;
  
  export const SingleSignInPage = ({
    provider,
    auto,
    onSignInSuccess,
  }: SingleSignInPageProps) => {
    const classes = useStyles();
    const authApi = useApi(provider.apiRef);
    const configApi = useApi(configApiRef);
    const { t } = useTranslationRef(coreComponentsTranslationRef);
  
    const [error, setError] = useState<Error>();
  
    // The SignIn component takes some time to decide whether the user is logged-in or not.
    // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
    // displayed for a split second when the user is already logged-in.
    const [showLoginPage, setShowLoginPage] = useState<boolean>(false);
  
    type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
    const login = async ({ checkExisting, showPopup }: LoginOpts) => {
      try {
        let identityResponse: BackstageIdentityResponse | undefined;
        if (checkExisting) {
          // Do an initial check if any logged-in session exists
          identityResponse = await authApi.getBackstageIdentity({
            optional: true,
          });
        }
  
        // If no session exists, show the sign-in page
        if (!identityResponse && (showPopup || auto)) {
          // Unless auto is set to true, this step should not happen.
          // When user intentionally clicks the Sign In button, autoShowPopup is set to true
          setShowLoginPage(true);
          identityResponse = await authApi.getBackstageIdentity({
            instantPopup: true,
          });
          if (!identityResponse) {
            throw new Error(
              `The ${provider.title} provider is not configured to support sign-in`,
            );
          }
        }
  
        if (!identityResponse) {
          setShowLoginPage(true);
          return;
        }
  
        const profile = await authApi.getProfile();
        onSignInSuccess(
          UserIdentity.create({
            identity: identityResponse.identity,
            authApi,
            profile,
          }),
        );
      } catch (err: any) {
        // User closed the sign-in modal
        setError(err);
        setShowLoginPage(true);
      }
    };
  
    useMountEffect(() => login({ checkExisting: true }));
  
    return showLoginPage ? (
      <Page themeId="home">
        <Header title={configApi.getString('app.title')} />
        <Content>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            component="ul"
            classes={classes}
          >
            <Grid component="li" item classes={classes}>
              <InfoCard
                variant="fullHeight"
                title={provider.title}
                actions={
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      login({ showPopup: true });
                    }}
                  >
                    {t('signIn.title')}
                  </Button>
                }
              >
                <Typography variant="body1">{provider.message}</Typography>
                {error && error.name !== 'PopupRejectedError' && (
                  <Typography variant="body1" color="error">
                    {error.message}
                  </Typography>
                )}
              </InfoCard>
            </Grid>
          </Grid>
        </Content>
      </Page>
    ) : (
      <Progress />
    );
  };
  
  export function SignInPage(props: Props) {
    return <SingleSignInPage {...props} />;
  }