import React from 'react';
import { Content, PageWithHeader } from '@backstage/core-components';
import { GroupsExplorerContent } from '@backstage-community/plugin-explore';

export const groupsPage = (
    <PageWithHeader themeId='' title='Org Structure'>
        <Content>
            <GroupsExplorerContent title='' />
        </Content>
    </PageWithHeader>
);
